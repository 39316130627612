.feature-servicos {
    padding: 100px 80px 0 80px;
    padding-bottom: 70px;
    color: #66666a;
    overflow: hidden;
}

.feature-servicos .titulo {
    font-size: 50px;
    margin-bottom: 12px;
}

.feature-servicos .descricao {
    font-size: 15px;
}

.icon {
    padding: 20px;
    font-size: 40px;
    color: #fff;
}

.servico-titulo {
    font-size: 22px;
}

.servico-descricao {
    font-size: 15px;
}

.coluna-servico .text-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.coluna-servico .text-center i {
    color: #fff;
}

.feature-servicos h2.titulo {
    font-family: 'Lexend Deca', sans-serif;
    color: #000;
}

.feature-servicos .coluna-servico h3.servico-titulo {
    color: #000;
}

@media screen and (max-width: 1366px) {
    .feature-servicos .titulo {
        font-size: 50px;
        margin-bottom: 12px;
    }
}

@media screen and (max-width: 990px) {
    .feature-servicos .titulo {
        font-size: 35px;
        margin-bottom: 10px;
    }
}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .feature-servicos {
        padding: 40px 10px 20px 10px;
        color: #66666a;
        overflow: hidden;
    }
    .feature-servicos .titulo {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
}

@media screen and (max-width: 370px) {
    .feature-servicos {
        padding: 40px 10px 20px 10px;
        color: #66666a;
        overflow: hidden;
    }
    .feature-servicos .titulo {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
}