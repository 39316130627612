.formulario-contacto {
    padding: 100px 80px 80px 80px;
    font-family: sans-serif;
    background-color: #fff;
    -webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
    padding: 100px 80px;
    box-sizing: border-box;
}

.formulario-contacto .titulo {
    margin-bottom: 10px;
    font-size: 40px;
    color: #212121;
    text-transform: capitalize;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 700;
    text-align: start;
}

.formulario-contacto .descricao {
    line-height: 1.8;
    color: #666;
    font-weight: 400;
    font-family: Open Sans, sans-serif;
    font-size: 15px;
}

.formulario-contacto .formulario-container {
    background-color: #fff;
    -webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
    box-shadow: 0 5px 40px 0 rgba(100, 73, 73, 0.11);
    padding: 35px;
}

.formulario-contacto .form-control {
    height: 48px;
    padding: 0 15px;
    line-height: normal;
    color: #212121;
    background-color: #f8f6f6;
    border: 1px solid #f8f6f6;
    border-radius: 5px;
    transition: .5s;
    box-shadow: unset !important;
    font-size: 14px;
    font-weight: 400;
    display: block;
    width: 100%;
    appearance: none;
    background-clip: padding-box;
}

.formulario-contacto .form-control.textarea {
    padding-top: 14px;
    min-height: 150px;
    /* Ajuste a altura conforme necessário */
    resize: vertical;
    /* Permitir apenas a redimensionamento vertical */
}

.formulario-contacto .btn {
    border: none;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 14px 35px;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    border-radius: 2px;
    font-family: 'Lexend Deca', sans-serif;
}

.formulario-contacto .btn:hover {
    background-color: var(--secondary-color);
    color: #fff;
}

.formulario-contacto .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

.formulario-contacto .form-label {
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    font-family: 'Lexend Deca', sans-serif;
}

@media screen and (max-width: 1366px) {}

@media screen and (max-width: 990px) {}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .formulario-contacto {
        padding: 20px 10px;
        background-color: #fff;
        -webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
        box-sizing: border-box;
    }
    .formulario-contacto .titulo {
        margin-bottom: 10px;
        font-size: 30px;
        color: #212121;
        text-transform: capitalize;
        font-family: 'Lexend Deca', sans-serif;
        font-weight: 700;
        text-align: start;
    }
}

@media screen and (max-width: 370px) {
    .formulario-contacto {
        padding: 20px 10px;
        background-color: #fff;
        -webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
        box-sizing: border-box;
    }
    .formulario-contacto .titulo {
        margin-bottom: 10px;
        font-size: 30px;
        color: #212121;
        text-transform: capitalize;
        font-family: 'Lexend Deca', sans-serif;
        font-weight: 700;
        text-align: start;
    }
}