/* Spinner.css */

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    /* Semi-transparent white overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.spinner {
    border: 8px solid #f3f3f3;
    /* Light grey */
    border-top: 8px solid var(--primary-color);
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* Others css */

.user-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    width: calc(100% - 2px);
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.user-list li {
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
}

.user-list li:last-child {
    border-bottom: none;
}

.user-list li img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.add-user-icon {
    margin-left: auto;
    color: #007bff;
}

.add-user-icon:hover {
    color: #0056b3;
    cursor: pointer;
}