/* styles/Footer.css */

.footer {
    padding-top: 20px;
    font-family: sans-serif;
    background: #f8f9fa;
    overflow: hidden;
    box-sizing: border-box;
}

.footer-row1,
.footer-row2,
.footer-row3 {
    color: #000;
    overflow: hidden;
    box-sizing: border-box;
}

.footer-row1 {
    padding: 60px 80px 20px 80px;
}

.footer-row2 {
    padding: 40px 80px;
}

.footer-row1 h3,
.footer-row2 h3,
h5 {
    padding-bottom: 20px;
    font-family: 'Lexend Deca', sans-serif;
}

.footer-row1 p,
.footer-row2 .list-unstyled li {
    color: #66666a;
    margin-bottom: 16px;
}

.footer-row3 {
    padding: 20px 80px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #FFF;
}

.footer-row3 p {
    font-family: 'falhada', sans-serif;
    font-size: 14px;
    padding-top: 20px;
}

.social-icons {
    display: flex;
}

.icon {
    background-color: var(--primary-color);
    /* background-color: #000; */
    width: 40px;
    /* Ajuste o tamanho conforme necessário */
    height: 40px;
    /* Ajuste o tamanho conforme necessário */
    border-radius: 50%;
    /* Faz com que o fundo seja circular */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    /* Espaçamento entre os ícones */
    color: #fff !important;
    text-align: center;
}

.social-network-icon {
    color: #000 !important;
    width: 40%;
    height: 40%;
}

.icon:last-child {
    margin-right: 0;
    /* Remove o espaçamento do último ícone */
}

.icon-large {
    font-size: 25px;
    margin-right: 5px;
    color: #FFF;
}

.subscribe-form {
    position: relative;
}

.subscribe-form button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 70px;
    font-size: 16px;
    border-radius: 10px;
    background: var(--primary-color);
    border: var(--primary-color);
    color: #fff;
    z-index: 2;
    /* Aumente ainda mais o valor do z-index */
}

.subscribe-form input[type="email"] {
    height: 72px;
    font-size: 16px;
    padding-left: 25px;
    border-radius: 10px;
    margin-right: -40px;
    z-index: 1;
}

.subscribe-form button:hover {
    background: var(--secondary-color);
    border: none;
    color: #fff;
}

.coluna-link-importante {
    padding-left: 80px !important;
}

.coluna-link {
    padding-top: 50px;
}

.coluna-link ul li a {
    color: #66666a;
}

.coluna-link ul li a {
    color: #66666a;
}

.coluna-link ul li a:hover {
    color: var(--primary-color);
}

.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 80px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.scroll-to-top:hover {
    background-color: var(--secondary-color);
}

@media screen and (max-width: 1366px) {
    .feature-servicos .titulo {
        font-size: 50px;
        margin-bottom: 12px;
    }
}

@media screen and (max-width: 990px) {
    .feature-servicos .titulo {
        font-size: 35px;
        margin-bottom: 10px;
    }
}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .footer-row1 {
        padding: 20px 10px;
    }
    .footer-row2 {
        padding: 20px 10px;
    }
    .footer-row3 {
        padding: 20px 10px;
    }
    .coluna-link-importante {
        padding-left: 10px !important;
    }
    .subscribe-form input[type="email"] {
        display: block;
        height: 72px;
        font-size: 16px;
        padding-left: 25px;
        border-radius: 10px;
        z-index: 1;
        position: relative;
    }
    .subscribe-form button {
        display: block;
        position: relative;
        transform: translateY(-50%);
        height: 70px;
        font-size: 16px;
        border-radius: 10px;
        background: var(--primary-color);
        border: var(--primary-color);
        color: #fff;
        z-index: 2;
        /* Aumente ainda mais o valor do z-index */
    }
}

@media screen and (max-width: 370px) {
    .subscribe-form button {
        display: block;
        position: relative;
        transform: translateY(-50%);
        height: 70px;
        font-size: 16px;
        border-radius: 10px;
        background: var(--primary-color);
        border: var(--primary-color);
        color: #fff;
        z-index: 2;
        /* Aumente ainda mais o valor do z-index */
    }
    .subscribe-form input[type="email"] {
        display: block;
        height: 72px;
        font-size: 16px;
        padding-left: 25px;
        border-radius: 10px;
        z-index: 1;
        position: relative;
    }
    .footer-row1 {
        padding: 20px 10px;
    }
    .footer-row2 {
        padding: 20px 10px;
    }
    .footer-row3 {
        padding: 20px 10px;
    }
    .coluna-link-importante {
        padding-left: 10px !important;
    }
}