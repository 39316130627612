.info-video {
    padding: 100px 80px 80px 80px;
    color: #66666a;
    font-family: sans-serif;
    overflow: hidden;
}

.info-content {
    padding-right: 40px;
}

.descricao-percentagem {
    margin-bottom: 15px;
}

.info-video .titulo {
    margin-bottom: 40px;
    font-family: 'Lexend Deca', sans-serif;
    color: black;
    text-align: left;
}

.info-video .descricao {
    margin-bottom: 40px;
    font-size: 15px;
}

.infoVideo-descricao {
    width: 100% !important;
}

.progress-container {
    margin-top: 20px;
}

.progress-item {
    display: flex;
    flex-direction: column;
    /* Adicionando para empilhar os elementos verticalmente */
    margin-bottom: 8px;
    position: relative;
    /* Adicionado para o posicionamento absoluto da barra de progresso cinza */
}

.progress-bar-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    /* Cor de fundo cinza */
}

.progress-bar {
    height: 10px;
    background-color: var(--primary-color) !important;
    /* Cor da barra de progresso principal */
}

.fundo-progress {
    margin-bottom: 20px;
    height: 10px;
    background-color: #f0f0f0;
    /* Cor da barra de progresso principal */
}

.progress-value {
    font-size: 16px;
    margin-top: 5px;
    /* Adicionando espaço entre a porcentagem e a barra de progresso */
}

.progress-label {
    font-size: 16px;
    color: black;
}

.video-overlay {
    position: relative;
}

.video-thumbnail {
    width: 100%;
    height: auto;
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

.play-icon:hover {
    background-color: var(--secondary-color);
    cursor: pointer;
}


/* Estilos para o modal */

.custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 1000;
    /* Para garantir que o modal apareça sobre outros elementos */
}


/* Estilos para o overlay do modal */

.custom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Cor de fundo semi-transparente */
    z-index: 999;
    /* Z-index inferior ao do modal para garantir que o modal fique sobreposto */
}


/* Responsividade para dispositivos menores */

@media screen and (max-width: 1366px) {
    .info-video .titulo {
        font-size: 50px;
        margin-bottom: 12px;
    }
}

@media screen and (max-width: 990px) {
    .info-video .titulo {
        font-size: 35px;
        margin-bottom: 10px;
    }
}

@media (max-width: 768px) {
    .info-video {
        padding: 80px 20px 0 20px;
    }
    .info-video .titulo {
        font-size: 35px;
    }
    .info-video .descricao {
        font-size: 14px;
    }
    .progress-label,
    .progress-value {
        font-size: 14px;
    }
    .play-icon {
        width: 60px;
        height: 60px;
    }
    .play-icon i {
        font-size: 30px;
    }
}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .info-video {
        padding: 40px 10px 20px 10px;
        color: #66666a;
        overflow: hidden;
    }
    .info-video .titulo {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
}

@media screen and (max-width: 370px) {
    .info-video {
        padding: 40px 10px 20px 10px;
        color: #66666a;
        overflow: hidden;
    }
    .info-video .titulo {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
}