/* Estilos para o menu superior */

.active {
    color: var(--primary-color);
}

.menu-superior-principal {
    position: absolute;
    top: 0;
    left: 5%;
    width: 100%;
    max-width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 20px 20px;
    z-index: 3;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
}

.fixed {
    position: fixed !important;
    left: 0;
    top: 0;
    width: 100% !important;
    max-width: 100% !important;
    background-color: white;
    padding: 20px 85px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    /* Optional: Add shadow for depth */
    z-index: 99;
    /* Optional: Ensure it stays on top of other elements */
    box-sizing: border-box;
}

.fixed ul.ul_primary li a {
    color: #fff;
    text-decoration: none;
    font-family: 'Lexend Deca', sans-serif;
    padding: 10px 0;
}


/* Estilos para o logotipo */

.logo img {
    width: 150px;
    /* border:2px solid #0ff; */
}


/* Estilos para a lista de itens do menu */

.menu-items ul.ul_primary {
    list-style-type: none;
    padding: 5px;
    margin: 0;
    /* border: 2px solid #f00; */
}


/* Estilos para cada item do menu */

.menu-items ul.ul_primary li {
    display: inline-block;
    margin-right: 20px;
    position: relative;
    /* Adiciona position relative ao item do menu pai */
    padding: 10px 0;
}

.menu-items ul.ul_primary li.li_entrar {
    display: none;
}


/* Estilos para o link em cada item do menu */

.menu-superior-principal .menu-items ul.ul_primary li a {
    /* color: white; */
    text-decoration: none;
    font-family: 'Lexend Deca', sans-serif;
    padding: 5px 0;
}


/* Estilos de hover para o link em cada item do menu */

.menu-items ul.ul_primary li a:hover {
    /* text-decoration: underline; */
    color: var(--primary-color);
}


/** Estilos para o dropdown */

.dropdown {
    display: none;
    /* Exibe os itens verticalmente */
    position: absolute;
    background-color: white;
    color: #000;
    padding: 10px;
    top: 100%;
    /* Posiciona o dropdown logo abaixo do item do menu */
    left: 0;
    /* Alinha o dropdown à esquerda do item do menu pai */
    z-index: 3;
    /* Ajustado para garantir que o dropdown fique acima do menu */
}


/* Exibir o dropdown quando o item do menu é hover */

.menu-items ul.ul_primary li:hover .dropdown {
    width: 300px;
    display: flex;
    flex-direction: column;
    position: absolute;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    border: none;
    width: 250px;
    border-radius: 0;
    -webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    padding-top: 20px;
    padding-left: 10;
    padding-right: 10;
    padding-bottom: 20px;
}


/* Estilos para cada item do dropdown */

.dropdown li {
    display: block;
    margin-top: 10px;
}


/* Estilos para o link em cada item do dropdown */

.dropdown li a {
    color: #000 !important;
    transition: color 0.3s;
    padding-left: 20px !important;
}


/* Estilos de hover para o link em cada item do dropdown */

.dropdown li a:hover {
    color: var(--primary-color) !important;
    transition: color 0.3s;
    text-decoration: none !important;
}


/* Estilos para os ícones */

.icons {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    /* border:2px solid #f0f; */
}


/* Estilos para o ícone de pesquisa */

.search-icon {
    margin-right: 10px;
    color: white;
}


/* Estilos para o botão de login */

.login-button {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
}


/* Estilos de hover para o botão de login */

.login-button:hover {
    /* text-decoration: underline; */
    background-color: var(--secondary-color);
}

.hamburger-menu {
    display: none;
    /* border:2px solid #f00; */
}


/* Media query para responsividade */

@media screen and (max-width: 1366px) {
    .menu-superior-principal .menu-items ul.ul_primary li a {
        color: white;
    }
}

@media screen and (max-width: 990px) {
    .menu-superior-principal {
        position: relative;
        top: 0;
        left: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        background-color: white;
        padding: 10px 10px;
        /* Reduzido o padding vertical */
        z-index: 3;
        /*border: 2px solid #f00;*/
    }
    .login-button {
        display: none;
    }
    .openMenu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: white;
        padding: 0;
        /* Removido o padding */
        /* border:2px solid #007bff; */
    }
    .openMenu ul.ul_primary {
        list-style-type: none;
        padding: 10px;
        margin: 0;
        display: block !important;
    }
    .openMenu ul.ul_primary li {
        display: block;
        margin-bottom: 5px;
        /* Reduzido o espaço entre os itens */
        padding: 5px 0;
        /* Reduzido o padding vertical */
        border-bottom: 1px solid var(--primary-color);
        color: #000;
    }
    .menu-items ul.ul_primary li.li_entrar {
        display: inline-block;
    }
    .openMenu ul.ul_primary li a {
        color: black;
        text-decoration: none;
        font-family: 'Lexend Deca', sans-serif;
    }
    .openMenu .dropdown {
        width: 100%;
        top: 0;
        left: 100%;
    }
    .openMenu .dropdown li {
        margin-top: 5px;
        /* Reduzido o espaço entre os itens do dropdown */
    }
    .openMenu .dropdown li a {
        color: #fff !important;
        transition: color 0.3s;
    }
    .openMenu .dropdown li a:hover {
        color: var(--secondary-color);
        /* text-decoration: underline; */
    }
    .hamburger-menu {
        display: block;
        margin-left: auto;
        /* Alterado para alinhar à direita */
        margin-right: 20px;
        /* Espaçamento à direita */
        z-index: 3;
        /* Garante que o ícone fique acima dos outros elementos */
    }
    .menu-items ul.ul_primary {
        display: none;
    }
    /* Exibir o dropdown quando o item do menu é hover */
    .menu-items ul.ul_primary li:hover .dropdown {
        display: flex;
        flex-direction: column;
        position: absolute;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        background: var(--primary-color);
        border: none;
        width: 250px;
        border-radius: 0;
        -webkit-transition: all 0.6s ease-in-out;
        transition: all 0.6s ease-in-out;
        padding-top: 20px;
        padding-left: 10;
        padding-right: 10;
        padding-bottom: 20px;
        left: 120px;
        top: 20px;
    }
    .menu-superior-principal .menu-items ul.ul_primary li a {
        color: #000;
    }
}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .menu-superior-principal {
        padding: 10px 10px;
        width: 100%;
        position: relative;
        /* Reduz o padding vertical */
    }
    .menu-items ul.ul_primary li.li_entrar {
        display: inline-block;
    }
    .openMenu ul.ul_primary li a {
        color: black;
        text-decoration: none;
        font-family: 'Lexend Deca', sans-serif;
    }
    .menu-superior-principal .menu-items ul.ul_primary li a {
        color: #000;
    }
}


/* Media query para dispositivos móveis */

@media screen and (max-width: 370px) {
    .menu-superior-principal {
        padding: 10px 10px;
        width: 100%;
        max-width: 100%;
        /* Reduz o padding vertical */
    }
    .openMenu {
        display: block;
    }
    .openMenu ul.ul_primary li {
        margin-bottom: 5px;
        /* Reduz o espaço entre os itens */
        padding: 5px 0;
        /* Reduz o padding vertical */
    }
    .menu-items ul.ul_primary li.li_entrar {
        display: inline-block;
    }
    .openMenu .dropdown li {
        margin-top: 5px;
        /* Reduz o espaço entre os itens do dropdown */
    }
    .menu-items ul.ul_primary {
        padding: 5px;
        display: none;
    }
    .menu-items ul.ul_primary li:hover .dropdown {
        display: flex;
        flex-direction: column;
        position: absolute;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        background: var(--primary-color);
        border: none;
        width: 250px;
        border-radius: 0;
        -webkit-transition: all 0.6s ease-in-out;
        transition: all 0.6s ease-in-out;
        padding-top: 20px;
        padding-left: 10;
        padding-right: 10;
        padding-bottom: 20px;
        left: 120px;
        top: 20px;
    }
    .openMenu ul.ul_primary li a {
        color: black;
        text-decoration: none;
        font-family: 'Lexend Deca', sans-serif;
    }
    .menu-superior-principal .menu-items ul.ul_primary li a {
        color: #000;
    }
}