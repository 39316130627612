.contact-info {
    padding: 100px 80px;
}

.contacto-container {
    text-align: center;
    background-color: #fff;
    -webkit-box-shadow: 0 0 11px 3px rgba(0, 0, 0, .05);
    box-shadow: 0 0 11px 3px rgba(0, 0, 0, .05);
    padding: 30px;
    margin-bottom: 30px;
    box-sizing: border-box;
    height: 259px;
}

.icon-container .icon {
    border: 1px dashed var(--primary-color);
    background-color: #fff;
    color: var(--primary-color);
}

.icon-container svg path {
    color: var(--primary-color);
}

.contacto-container:hover .icon-container .icon {
    background-color: var(--primary-color);
    transition: background-color 0.5s;
}

.contacto-container:hover svg path {
    color: #fff;
    transition: color 0.5s;
}

.icon-container {
    /* border: 1px dashed #086ad8; */
    border-radius: 50%;
    font-size: 20px;
    display: inline-block;
    color: var(--primary-color);
    transition: color 0.5s;
    margin-bottom: 25px;
}

.icon-container:hover {
    color: #fff;
}

.contact-info .title {
    font-size: 25px;
    margin-bottom: 10px;
    color: #212121;
    text-transform: capitalize;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 700;
}

.contact-info .description {
    line-height: 1.8;
    color: #666;
    font-weight: 400;
    font-family: Open Sans, sans-serif;
    font-size: 15px;
}

@media screen and (max-width: 1366px) {}

@media screen and (max-width: 990px) {}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .contact-info {
        padding: 20px 10px;
    }
}

@media screen and (max-width: 370px) {
    .contact-info {
        padding: 20px 10px;
    }
}