.section-heading h2 {
    font-family: 'Lexend Deca', sans-serif;
    font-size: 35px;
}

.testemunhos1 {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 100px 80px;
}

.slick-dots {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
}

.slick-dots li {
    display: inline-block;
    margin: 0 5px;
}

.dot {
    background-color: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    border: none;
}

.dot.slick-active {
    background-color: var(--primary-color);
    width: 45px;
    height: 45px;
}

.testimonial-item {
    text-align: center;
    max-width: 860px;
    margin: 0 auto;
}

.quote-icon {
    display: inline-block;
    height: 80px;
    width: 80px;
    line-height: 80px;
    background-color: #086AD8;
    color: #ffffff;
    font-size: 40px;
    border-radius: 50px;
    padding: 10px;
}

.testimonial-text {
    font-style: italic;
    max-width: 860px;
    margin: 20px auto 0;
    font-size: 17px;
    font-weight: 600;
    color: #333;
}

.testimonial-author {
    margin-top: 50px;
    position: relative;
}

.author-name {
    font-size: 22px;
    margin-bottom: 5px;
}

.author-position {
    font-size: 14px;
    margin-bottom: 5px;
}

@media screen and (max-width: 1366px) {
    .section-heading h2 {
        font-size: 50px;
        margin-bottom: 12px;
    }
}

@media screen and (max-width: 990px) {
    .testemunhos1 {
        padding: 40px 80px 40px 80px;
    }
    .section-heading h2 {
        font-size: 35px;
        margin-bottom: 10px;
    }
}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .testemunhos1 {
        padding: 40px 10px 20px 10px;
    }
    .section-heading h2 {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
}

@media screen and (max-width: 370px) {
    .testemunhos1 {
        padding: 20px 10px 20px 10px;
    }
    .section-heading h2 {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
}