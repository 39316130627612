.treinamentos-destaque {
    padding: 100px 80px;
}

.treinamento {
    margin-bottom: 30px;
    position: relative;
    box-sizing: border-box;
}

.treinamento .imagem {
    text-align: center;
    box-sizing: border-box;
}

.treinamento .imagem img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.treinamento .conteudo {
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
    padding: 25px;
    transition: .5s;
    box-sizing: border-box;
}

.treinamento:hover .conteudo {
    background-color: var(--primary-color);
    transition: .5s;
}

.treinamento:hover .titulo {
    color: #fff;
    transition: .5s;
}

.treinamento:hover .descricao {
    color: #fff;
    transition: .5s;
}

.treinamento:hover .ver-turmas {
    color: #000;
    transition: .5s;
}

.treinamento .ver-turmas {
    font-size: 14px;
    font-weight: 600;
    color: #66666a;
    margin-bottom: 8px;
    display: block;
    text-align: left;
}

.treinamento .titulo {
    font-size: 20px;
    margin-bottom: 8px;
    transition: .5s;
    color: #212121;
    text-transform: capitalize;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 700;
    text-align: left;
}

.treinamento .descricao {
    font-size: 14px;
    transition: .5s;
    text-align: left;
    display: block;
    color: #66666a;
    width: 100%;
    height: 75px;
}

@media screen and (max-width: 1366px) {
    .treinamento .titulo {
        margin-bottom: 12px;
    }
}

@media screen and (max-width: 990px) {
    .treinamentos-destaque .titulo {
        font-size: 35px;
        margin-bottom: 10px;
    }
}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .treinamentos-destaque {
        padding: 40px 10px 20px 10px;
        color: #66666a;
        overflow: hidden;
    }
    .treinamentos-destaque .titulo {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
}

@media screen and (max-width: 370px) {
    .treinamentos-destaque {
        padding: 40px 10px 20px 10px;
        color: #66666a;
        overflow: hidden;
    }
    .treinamentos-destaque .titulo {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
}