.projetos-destaque {
    background: #f2f0fe;
    padding: 100px 80px 80px 80px;
    color: #66666a;
    font-family: sans-serif;
    overflow: hidden;
}

.projetos-destaque .titulo {
    font-size: 50px;
    margin-bottom: 40px;
    font-family: 'Lexend Deca', sans-serif;
    color: black;
}

.projetos-destaque .descricao {
    margin-bottom: 40px;
    font-size: 15px;
}

.destaque-item {
    background-color: white;
}

.destaque-imagem {
    text-align: center;
    padding: 20px 0;
}

.destaque-info {
    background-color: #f2f0fe;
    padding: 30px;
    box-sizing: border-box;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}

.destaque-info a {
    text-decoration: none;
    color: black;
}

.destaque-info a h3 {
    font-family: 'Lexend Deca', sans-serif;
}

.destaque-info:hover {
    background-color: var(--primary-color);
    color: white;
}

.destaque-info:hover a h3,
.destaque-info:hover a span {
    color: white;
}

@media screen and (max-width: 1366px) {
    .projetos-destaque .titulo {
        font-size: 50px;
        margin-bottom: 12px;
    }
}

@media screen and (max-width: 990px) {
    .projetos-destaque .titulo {
        font-size: 35px;
        margin-bottom: 10px;
    }
}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .projetos-destaque {
        padding: 40px 10px 20px 10px;
        color: #66666a;
        overflow: hidden;
    }
    .projetos-destaque .titulo {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
}

@media screen and (max-width: 370px) {
    .projetos-destaque {
        padding: 40px 10px 20px 10px;
        color: #66666a;
        overflow: hidden;
    }
    .projetos-destaque .titulo {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
}