.artigo-sidebar {
    padding: 100px 80px 80px 20px;
    background-color: #fff;
    overflow: hidden;
    box-sizing: border-box;
}

.artigo-info {
    padding: 100px 20px 80px 80px;
}

.blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin-bottom: 20px;
    margin-top: 20px;
    border-left: 2px solid var(--primary-color);
}

.blockquote p {
    color: #212121;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 24px !important;
}

.search-input {
    background-color: transparent;
    height: 50px;
    padding: 6px 15px;
    border: 1px solid #eee;
    width: 100%;
    display: block;
    outline: 0;
    transition: .5s;
}

.search-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 50px;
    width: 50px;
    z-index: 1;
    border: none;
    color: #fff;
    background-color: #086ad8;
    transition: .5s;
    cursor: pointer;
    outline: 0 !important;
}

.sidebar-title {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #212121;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    overflow: hidden;
    box-sizing: border-box;
}

.sidebar-line {
    border: none;
    height: 1px;
    background-color: #ccc;
    overflow: hidden;
    box-sizing: border-box;
}

.artigo-widget {
    overflow: hidden;
    margin-bottom: 20px;
    overflow: hidden;
    box-sizing: border-box;
}

.artigo-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
}

.artigo-img {
    width: 100px;
    height: auto;
    margin-right: 10px;
}

.artigo-content {
    flex: 1;
}

.artigo-date {
    display: block;
    color: #666;
    text-transform: capitalize;
    margin-top: -2px;
    margin-bottom: 3px;
    font-size: 14px;
}

.artigo-title {
    margin-bottom: 0;
    line-height: 1.4;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}