/* Importação das fontes */

@font-face {
    font-family: 'Lexend Deca';
    src: url('/public/fonts/lexenda/LexendDeca-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lexend Deca';
    src: url('/public/fonts/lexenda/LexendDeca-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lexend Deca';
    src: url('/public/fonts/lexenda/LexendDeca-Light.ttf') format('truetype');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'Lexend Deca';
    src: url('/public/fonts/lexenda/LexendDeca-Medium.ttf') format('truetype');
    font-weight: medium;
    font-style: normal;
}

* {
    /* border: 2px solid #f00; */
}


/* style.css */


/* Cores */

:root {
    --primary-color: rgb(0, 156, 249);
    --secondary-color: #00A99D;
}


/* Estilos Globais */

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    font-family: 'falhada', sans-serif;
}


/* Links */

a {
    color: var(--primary-color);
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

h5 {
    font-size: 25px;
}

h3 {
    font-size: 22px;
}

div.bar-title {
    height: 4px;
    width: 85px;
    background: #ffffff;
    margin: 20px auto 60px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
    text-align: center;
}

div.bar-title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: var(--primary-color);
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-name: MOVE-BG;
}

@keyframes MOVE-BG {
    0% {
        width: 10%;
        /* Começa sem largura (não visível) */
    }
    100% {
        width: 100%;
        /* Termina com largura total (visível) */
    }
}

@media screen and (max-width: 600px) {
    h1,
    h1,
    h3 {
        font-size: 30px;
        /* Reduz ainda mais o tamanho do título em telas menores que 600px */
    }
}

@media screen and (max-width: 400px) {
    h1,
    h2,
    h3 {
        font-size: 28px;
        /* Reduz ainda mais o tamanho do título em telas menores que 600px */
    }
}

@media screen and (max-width: 375px) {
    h1,
    h2,
    h3 {
        font-size: 16px;
        /* Reduz ainda mais o tamanho do título em telas menores que 600px */
    }
}