/* src/stylesComponent/bannerPrincipal.css */

.banner-slider {
    overflow: hidden;
}

.banner-slide {
    position: relative;
    height: 100%;
    /* Definir a altura como 100% */
    margin: 0;
    /* Remover margens */
    padding: 0;
    /* Remover padding */
    overflow: hidden;
}

.banner-slider img {
    width: 100%;
    height: 100%;
    /* Definir a altura como 100% */
    object-fit: cover;
    /* Para garantir que a imagem cubra todo o espaço */
    margin: 0;
    /* Remover margens */
    padding: 0;
    /* Remover padding */
}

.banner-slide {
    position: relative;
    height: 100%;
    /* Definir a altura como 100% */
}

.banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    width: calc(100% - 40px);
    max-width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid #f00; */
    padding: 20px 20px;
}

.banner-text {
    flex: 1;
}

.banner-image {
    width: 40%;
    margin-left: 20px;
    display: block;
}

.banner-title {
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 50px;
}

.banner-description {
    font-size: 15px;
    margin-bottom: 0px;
}

.banner-slider .slick-slide::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
    background: rgb(0, 0, 0, 0.2);
}

.banner-label {
    font-size: 16px;
    color: #fff;
    margin-bottom: 5px;
}

.cta-button {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    margin-top: 50px;
}

.cta-button:hover {
    background-color: var(--secondary-color);
}


/* Regras de mídia para responsividade */


/* Media query para responsividade */

@media screen and (max-width: 990px) {}

@media screen and (max-width: 870px) {
    .banner-content {
        padding-top: 60px;
        /* Aumenta o espaço superior em telas menores */
    }
    .banner-image {
        display: none;
        /* Oculta a imagem em telas menores que 870px */
    }
    .banner-title {
        font-size: 40px;
        /* Reduz o tamanho do título em telas menores que 870px */
    }
}

@media screen and (max-width: 600px) {
    .banner-content {
        padding-top: 60px;
        /* Aumenta o espaço superior em telas menores */
    }
    .banner-title {
        font-size: 30px;
        /* Reduz ainda mais o tamanho do título em telas menores que 600px */
    }
    .cta-button,
    .banner-label {
        display: none;
    }
    .hamburger-menu {
        display: block;
        margin-left: 64px;
    }
}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .banner-content {
        padding-top: 80px;
        /* Aumenta o espaço superior em telas menores */
    }
    .banner-title {
        font-size: 24px;
        /* Reduz ainda mais o tamanho do título em telas menores que 400px */
    }
    .cta-button,
    .banner-label {
        display: none;
    }
    .hamburger-menu {
        display: block;
        margin-left: 64px;
    }
}

@media screen and (max-width: 375px) {
    .banner-slider .banner-content {
        padding-top: 20px;
        /* Aumenta o espaço superior em telas menores */
    }
    .banner-slider .banner-title {
        font-size: 16px;
        /* Reduz ainda mais o tamanho do título em telas menores que 400px */
    }
    .cta-button,
    .banner-label {
        display: none;
    }
    .hamburger-menu {
        display: block;
        margin-left: 64px;
    }
}

@media screen and (max-width: 360px) {
    .banner-slider .banner-content {
        padding-top: 60px;
        /* Aumenta o espaço superior em telas menores */
    }
    .hamburger-menu {
        display: block;
        margin-left: 64px;
    }
}