.missao-visao-valor {
    background-color: #fff;
    overflow: hidden;
    box-sizing: border-box;
    color: #666666;
}

.missao-visao-valor .title {
    font-size: 40px;
    margin: 0 0 15px 0;
    text-transform: uppercase;
    font-family: 'Lexend Deca', sans-serif;
    padding-left: 80px;
    color: #000;
}

.missao-visao-valor .card-block {
    font-size: 1em;
    position: relative;
    margin: 0;
    padding: 60px 80px 100px 0px;
    border: none;
    border-top: 1px solid rgba(34, 36, 38, .1);
    box-shadow: none;
}

.missao-visao-valor .card-block p {
    margin-bottom: 15px;
    line-height: 1.8;
    color: #666666;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    padding-left: 80px;
}

.missao-visao-valor .card {
    font-size: 1em;
    overflow: hidden;
    padding: 5;
    border: none;
    border-radius: .28571429rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    margin-top: 20px;
}

.missao-visao-valor .carousel-indicators li {
    border-radius: 12px;
    width: 12px;
    height: 12px;
    background-color: #404040;
}

.missao-visao-valor .carousel-indicators li {
    border-radius: 12px;
    width: 12px;
    height: 12px;
    background-color: #404040;
}

.missao-visao-valor .carousel-indicators .active {
    background-color: white;
    max-width: 12px;
    margin: 0 3px;
    height: 12px;
}

.missao-visao-valor .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.missao-visao-valor .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.missao-visao-valor .btn {
    margin-top: auto;
}

.missao-visao-valor .tab-pane h3 {
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
}

.missao-visao-valor .tab-pane li {
    margin-bottom: 15px;
}

.missao-visao-valor .svg-inline--fa {
    color: var(--primary-color);
    margin-right: 10px;
}


/* Adicione ao seu arquivo MissaoVisaoValor.css */


/* Estilos para as abas */


/* CSS Adicionado */

.tabs {
    padding: 20px 80px 20px 0px;
    margin-left: 80px;
    overflow: hidden;
    box-sizing: border-box;
}

.tab-list {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #ccc;
}

.tab-title {
    cursor: pointer;
    padding: 10px 20px 20px 20px;
    /*border: 1px solid #ccc;*/
    border-radius: 5px 5px 0 0;
    transition: background-color 0.3s ease;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Lexend Deca', sans-serif;
}

.tab-title.active {
    /* background-color: #f0f0f0; */
    border-bottom: 1px solid var(--primary-color);
}

.tab-content {
    /* border: 1px solid #ccc; */
    border-top: none;
    border-radius: 0 0 5px 5px;
    padding-top: 40px;
}

.tab-pane {
    display: none;
}

.tab-pane h3 {
    font-family: 'Lexend Deca', sans-serif;
}

.tab-pane ul {
    text-decoration: none;
    list-style: none;
}

.tab-pane.active {
    display: block;
}

p.tab-description {
    margin-bottom: 15px;
    padding: 0 !important;
}


/* Estilos para os botões */

.btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: var(--secondary-color);
    color: #fff;
}

@media screen and (max-width: 1366px) {}

@media screen and (max-width: 990px) {}

@media (max-width: 768px) {
    .row.missao-visao-valor {
        flex-direction: column-reverse;
        overflow: hidden;
    }
    .col-sm-6 {
        width: 100%;
    }
    .card-block {
        padding-left: 20px;
        padding-right: 20px;
    }
    .tabs {
        margin-left: 0;
        padding-left: 0;
    }
    .tab-list {
        flex-direction: row;
        overflow-x: auto;
    }
    .tab-title {
        padding: 10px;
    }
    .tab-content {
        padding-top: 20px;
    }
    .tab-pane h3 {
        font-size: 16px;
    }
    .tab-pane ul {
        padding-left: 0;
    }
    .btn {
        width: 100%;
    }
    .missao-visao-valor .title {
        font-size: 35px;
        margin: 0 0 10px 0;
        text-transform: uppercase;
        font-family: 'Lexend Deca', sans-serif;
        padding-left: 10px;
        color: #000;
    }
    .missao-visao-valor .card-block {
        font-size: 1em;
        position: relative;
        margin: 0;
        padding: 20px 10px 20px 10px;
        border: none;
        border-top: 1px solid rgba(34, 36, 38, .1);
        box-shadow: none;
    }
    .missao-visao-valor .card-block p {
        margin-bottom: 10px;
        line-height: 1.8;
        color: #666666;
        font-weight: 400;
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
        padding-left: 10px;
    }
    .missao-visao-valor .card {
        font-size: 1em;
        overflow: hidden;
        padding: 5px;
        border: none;
        border-radius: .28571429rem;
        box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
        margin-top: 20px;
    }
}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .missao-visao-valor .title {
        font-size: 30px;
        margin: 0 0 10px 0;
        text-transform: uppercase;
        font-family: 'Lexend Deca', sans-serif;
        padding-left: 10px;
        color: #000;
    }
    .missao-visao-valor .card-block {
        font-size: 1em;
        position: relative;
        margin: 0;
        padding: 20px 10px 20px 10px;
        border: none;
        border-top: 1px solid rgba(34, 36, 38, .1);
        box-shadow: none;
    }
    .missao-visao-valor .card-block p {
        margin-bottom: 10px;
        line-height: 1.8;
        color: #666666;
        font-weight: 400;
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
        padding-left: 10px;
    }
    .missao-visao-valor .card {
        font-size: 1em;
        overflow: hidden;
        padding: 5px;
        border: none;
        border-radius: .28571429rem;
        box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
        margin-top: 20px;
    }
}

@media screen and (max-width: 370px) {
    .missao-visao-valor .title {
        font-size: 30px;
        margin: 0 0 10px 0;
        text-transform: uppercase;
        font-family: 'Lexend Deca', sans-serif;
        padding-left: 10px;
        color: #000;
    }
    .missao-visao-valor .card-block {
        font-size: 1em;
        position: relative;
        margin: 0;
        padding: 20px 10px 20px 10px;
        border: none;
        border-top: 1px solid rgba(34, 36, 38, .1);
        box-shadow: none;
    }
    .missao-visao-valor .card-block p {
        margin-bottom: 10px;
        line-height: 1.8;
        color: #666666;
        font-weight: 400;
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
        padding-left: 10px;
    }
    .missao-visao-valor .card {
        font-size: 1em;
        overflow: hidden;
        padding: 5px;
        border: none;
        border-radius: .28571429rem;
        box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
        margin-top: 20px;
    }
}