.banner {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    background-attachment: fixed;
    box-sizing: border-box;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Overlay preto com transparência */
}

.banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;
    /* Para colocar o conteúdo sobre o overlay */
}

.banner .banner-title {
    margin-top: 0;
    text-transform: capitalize;
    font-family: "Lexend deca", sans-serif;
    margin-bottom: 0;
    /* color: #ffffff; */
    font-size: 50px;
}

.pagination {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    text-align: center;
    /* Alinhar os itens ao centro */
}

.page-item {
    color: #ffffff;
    display: inline-block;
    position: relative;
    font-weight: 400;
    font-size: 17px;
    margin-right: 25px;
}

.page-item.active {
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .banner .banner-title {
        font-size: 30px;
        /* Reduz ainda mais o tamanho do título em telas menores que 600px */
    }
}

@media screen and (max-width: 400px) {
    .banner .banner-title {
        font-size: 28px;
        /* Reduz ainda mais o tamanho do título em telas menores que 600px */
    }
}

@media screen and (max-width: 375px) {
    .banner .banner-title {
        font-size: 16px;
        /* Reduz ainda mais o tamanho do título em telas menores que 600px */
    }
}