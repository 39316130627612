.newsletter1 {
    color: #ffffff;
    /* Definindo a cor do texto como branco */
    position: relative;
    /* Adicionando para definir a posição absoluta do conteúdo sobre a imagem de fundo */
    background-size: cover;
    background-attachment: fixed;
    background-position: top;
    overflow: hidden;
}

.newsletter1-bg {
    height: 500px;
    /* Altura da imagem de fundo */
    position: relative;
    overflow: hidden;
    padding-top: 100px;
    padding-bottom: 100px;
}

.newsletter1-content {
    max-width: 750px;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    /* Posicionando absolutamente sobre a imagem de fundo */
    top: 50%;
    /* Alinhando verticalmente ao centro */
    left: 50%;
    /* Alinhando horizontalmente ao centro */
    transform: translate(-50%, -50%);
    /* Corrigindo o alinhamento */
    z-index: 1;
    /* Para sobrepor o conteúdo sobre a imagem de fundo */
}

.newsletter1-title {
    font-size: 35px;
    margin-bottom: 10px;
    font-family: 'Lexend Deca', sans-serif;
}

.newsletter1-description {
    margin-bottom: 15px;
    line-height: 1.8;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
}

.newsletter1-form {
    max-width: 550px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
}

.newsletter1-form input[type="text"] {
    display: block;
    width: 100%;
    background-color: #ffffff;
    border: none;
    height: 65px;
    padding-left: 25px;
    border-radius: 70px;
    outline: 0;
    color: #212121;
}

.newsletter1-form button {
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: var(--primary-color);
    color: #ffffff;
    border: none;
    height: 55px;
    padding: 0 30px;
    border-radius: 70px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    line-height: 55px;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
}

.newsletter1-form button:hover {
    background-color: var(--secondary-color);
}

@media screen and (max-width: 1366px) {}

@media screen and (max-width: 990px) {
    .newsletter1-content {
        max-width: 100%;
    }
    .newsletter1-title {
        font-size: 30px;
        margin-bottom: 10px;
    }
}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .newsletter1-content {
        max-width: 100%;
    }
    .newsletter1-content {
        max-width: 100%;
    }
    .newsletter1-title {
        font-size: 20px;
        margin-bottom: 8px;
        width: 100%;
    }
    .newsletter1-form button {
        position: relative;
        right: 5px;
        top: 5px;
        background-color: var(--primary-color);
        color: #ffffff;
        border: none;
        height: 55px;
        padding: 0 30px;
        border-radius: 70px;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        line-height: 55px;
        font-size: 15px;
        font-weight: 700;
        cursor: pointer;
    }
}

@media screen and (max-width: 370px) {
    .newsletter1-title {
        font-size: 25px;
        margin-bottom: 8px;
        width: 100%;
    }
    .newsletter1-content {
        max-width: 100%;
    }
    .newsletter1-form button {
        position: relative;
        right: 5px;
        top: 5px;
        background-color: var(--primary-color);
        color: #ffffff;
        border: none;
        height: 55px;
        padding: 0 30px;
        border-radius: 70px;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        line-height: 55px;
        font-size: 15px;
        font-weight: 700;
        cursor: pointer;
    }
}