.servico-video-container {
    padding: 100px 80px;
}

.video-overlay {
    position: relative;
}

.servico-video-container .video-thumbnail {
    width: 100%;
    height: 400px;
    object-fit: cover;
    top: 0px;
    /* Para garantir que a imagem cubra todo o espaço */
    margin: 0;
    /* Remover margens */
    padding: 0;
    /* Remover padding */
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

.play-icon:hover {
    background-color: var(--secondary-color);
    cursor: pointer;
}

.play-icon:hover svg {
    color: #fff;
}

.video-titulo {
    color: #212121;
    text-transform: capitalize;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 22px;
    margin-top: 20px;
}

.video-descricao {
    line-height: 1.8;
    color: #666;
    font-weight: 400;
    font-size: 15px;
}

@media screen and (max-width: 1366px) {}

@media screen and (max-width: 990px) {}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .servico-video-container {
        padding: 20px 10px;
    }
}

@media screen and (max-width: 370px) {
    .servico-video-container {
        padding: 20px 10px;
    }
}