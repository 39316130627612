.faqs-destaque {
    padding: 100px 80px 80px 80px;
    color: #66666a;
    font-family: sans-serif;
    overflow: hidden;
}

.texto-video-destaque {
    padding: 0 80px 0 80px;
    color: #66666a;
    font-family: sans-serif;
    overflow: hidden;
}

.texto-coluna-destaque {
    border-top: 1px solid #c2c2c2;
}

.faqs-destaque .titulo {
    font-size: 50px;
    margin-bottom: 40px;
    font-family: 'Lexend Deca', sans-serif;
    color: black;
    text-align: center;
}

.faqs-destaque .descricao {
    margin-bottom: 80px;
    font-size: 15px;
    width: 60%;
}

.faqs-thumbnail {
    width: 100%;
    height: auto;
}

.accordion-item {
    margin-bottom: 20px;
    background-color: #f7f5fd;
}

.accordion-header {
    cursor: pointer;
    border-radius: 5px;
    display: block;
    margin-bottom: 0;
    background-color: #f7f5fd;
    padding: 20px 25px;
    color: #212121;
    position: relative;
    font-family: "Lexend deca", sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.accordion-header:hover {
    background-color: var(--primary-color);
    color: #fff;
}

.icon-plus::after {
    content: '+';
}

.icon-minus::after {
    content: '-';
}

.accordion-content {
    padding: 20px 25px;
    color: #666666;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    line-height: 1.8;
    border-radius: 5px;
    display: block;
    margin-bottom: 0;
}

@media screen and (max-width: 1366px) {
    .faqs-destaque .titulo {
        font-size: 50px;
        margin-bottom: 12px;
    }
}

@media screen and (max-width: 990px) {
    .faqs-destaque .titulo {
        font-size: 35px;
        margin-bottom: 10px;
    }
}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .faqs-destaque {
        padding: 40px 10px 20px 10px;
        color: #66666a;
        overflow: hidden;
    }
    .faqs-destaque .titulo {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
    .texto-video-destaque {
        padding: 20px 10px
    }
}

@media screen and (max-width: 370px) {
    .faqs-destaque {
        padding: 40px 10px 20px 10px;
        color: #66666a;
        overflow: hidden;
    }
    .faqs-destaque .titulo {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
    .texto-video-destaque {
        padding: 20px 10px
    }
}