.artigos-destaque {
    padding: 100px 80px 80px 80px;
    color: #66666a;
    font-family: sans-serif;
    box-sizing: border-box;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
    overflow: hidden;
}

.artigos-destaque .titulo {
    font-size: 50px;
    margin-bottom: 40px;
    font-family: 'Lexend Deca', sans-serif;
    color: black;
    text-align: center;
}

.artigos-destaque .descricao {
    margin-bottom: 80px;
    font-size: 15px;
}

.artigo {
    margin-bottom: 40px;
    background-color: #f7f5fd;
    padding: 20px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}

.artigo-imagem {
    max-width: 100%;
    height: auto;
    margin-bottom: 18px;
}

.artigo-data {
    font-size: 14px;
    color: #086AD8;
}

.artigo-titulo {
    font-size: 22px;
    margin: 10px 0 6px 0;
    line-height: 1.4;
    font-family: 'Lexend Deca', sans-serif;
}

.artigo-titulo a {
    color: #212121;
    text-decoration: none;
    transition: 0.5s;
}

.artigo-titulo a:hover {
    color: #086AD8;
}

.artigo-descricao {
    font-size: 14px;
    margin-bottom: 10px;
}

.artigo-link {
    text-decoration: none;
    transition: 0.5s;
    color: #212121;
}

.artigo-link:hover {
    color: #086AD8;
}

@media screen and (max-width: 1366px) {
    .artigos-destaque .titulo {
        font-size: 50px;
        margin-bottom: 12px;
    }
}

@media screen and (max-width: 990px) {
    .artigos-destaque .titulo {
        font-size: 35px;
        margin-bottom: 10px;
    }
}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .artigos-destaque {
        padding: 40px 10px 20px 10px;
        color: #66666a;
        overflow: hidden;
    }
    .artigos-destaque .titulo {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
}

@media screen and (max-width: 370px) {
    .artigos-destaque {
        padding: 40px 10px 20px 10px;
        color: #66666a;
        overflow: hidden;
    }
    .artigos-destaque .titulo {
        font-size: 30px;
        margin-bottom: 8px;
        width: 100%;
    }
}