.servicos-destaque {
    padding: 100px 80px;
}

.servico {
    margin-bottom: 30px;
    position: relative;
    box-sizing: border-box;
}

.servico .imagem {
    text-align: center;
    box-sizing: border-box;
}

.servico .imagem img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.servico .conteudo {
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
    background-color: #fff;
    padding: 30px 30px 10px 30px;
    text-align: center;
    position: relative;
    z-index: 1;
    max-width: 320px;
    margin: -50px auto 0;
    box-sizing: border-box;
}

.servico:hover .conteudo {
    background-color: var(--primary-color);
    transition: .5s;
}

.servico:hover .titulo {
    color: #fff;
    transition: .5s;
}

.servico:hover .descricao {
    color: #fff;
    transition: .5s;
}

.servico .titulo {
    font-size: 16px;
    margin-bottom: 8px;
    transition: .5s;
    color: #212121;
    text-transform: capitalize;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 700;
    text-align: center;
}

.servico .descricao {
    font-size: 12px;
    font-weight: 600;
    transition: .5s;
    text-align: center;
    display: inline-block;
    color: #66666a;
}

@media screen and (max-width: 1366px) {
    .feature-servicos .titulo {
        font-size: 50px;
        margin-bottom: 12px;
    }
}

@media screen and (max-width: 990px) {
    .feature-servicos .titulo {
        font-size: 35px;
        margin-bottom: 10px;
    }
}


/* Meu telefone Sansung */

@media screen and (max-width: 400px) {
    .servicos-destaque {
        padding: 40px 10px 20px 10px;
    }
    .servicos-destaque .titulo {
        font-size: 30px;
    }
}

@media screen and (max-width: 370px) {
    .servicos-destaque {
        padding: 40px 10px 20px 10px;
    }
    .servicos-destaque .titulo {
        font-size: 30px;
    }
}